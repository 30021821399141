<template>
  <div>
    <h4 style="text-align: center; margin-top: 1%">CTT</h4>
    <div class="container">
      <div class="row">
        <div class="col">
          Envios Correio Azul: <b v-if="checked == false">Desligado</b>
          <b v-else>Ligado</b>
        </div>
        <div class="col">
          <b-form-checkbox
            v-model="checked"
            name="check-button"
            @change="updateValid()"
            switch
          >
          </b-form-checkbox>
        </div>
        <div class="container">
          <h4 style="text-align: center; margin-top: 1%">CTT Expresso</h4>
          <div class="col">
            Valor limite sem seguro
            <input type="text" v-model="no_insurance_limit" />
            <button
              class="update-button"
              style="margin-left: 10px"
              @click="update_ctt_threshold"
            >
              Atualizar
            </button>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <br />
    <h4 style="text-align: center">Shipping</h4>
    <div style="align-items: center">
      <table
        style="
          text-align: center;
          border-spacing: 10px;
          border-collapse: separate;
          margin-left: auto;
          margin-right: auto;
        "
      >
        <tr>
          <th>Companhia</th>
          <th>Fuel</th>
          <th>Inflação</th>
          <th>Seguro (por Kg)</th>
          <th>Taxa mau serviço</th>
          <th>Taxa mau serviço (Vol)</th>
          <th></th>
        </tr>
        <tr>
          <td>CTT</td>
          <td>
            <input
              id="length"
              type="text"
              class="form-control"
              v-model="shippingVariables.ctt.fuel"
            />
          </td>
          <td>
            <input
              id="length"
              type="text"
              class="form-control"
              v-model="shippingVariables.ctt.inflation"
            />
          </td>
          <td>
            <input
              id="length"
              type="text"
              class="form-control"
              v-model="shippingVariables.ctt.insurance"
            />
          </td>
          <td>
            <input
              id="length"
              type="text"
              class="form-control"
              v-model="shippingVariables.ctt.bad_service"
            />
          </td>
          <td>
            <input
              id="length"
              type="text"
              class="form-control"
              v-model="shippingVariables.ctt.bad_service_vol"
            />
          </td>
          <td>
            <a
              id="updateValues"
              @click="updateVariables(shippingVariables.ctt, 'CTT')"
              >🔄</a
            >
          </td>
        </tr>
        <tr>
          <td>CTT Expresso</td>
          <td>
            <input
              id="length"
              type="text"
              class="form-control"
              v-model="shippingVariables.ctt_expresso.fuel"
            />
          </td>
          <td>
            <input
              id="length"
              type="text"
              class="form-control"
              v-model="shippingVariables.ctt_expresso.inflation"
            />
          </td>
          <td>
            <input
              id="length"
              type="text"
              class="form-control"
              v-model="shippingVariables.ctt_expresso.insurance"
            />
          </td>
          <td>
            <input
              id="length"
              type="text"
              class="form-control"
              v-model="shippingVariables.ctt_expresso.bad_service"
            />
          </td>
          <td>
            <input
              id="length"
              type="text"
              class="form-control"
              v-model="shippingVariables.ctt_expresso.bad_service_vol"
            />
          </td>
          <td>
            <a
              id="updateValues"
              @click="
                updateVariables(shippingVariables.ctt_expresso, 'CTT Expresso')
              "
              >🔄</a
            >
          </td>
        </tr>
        <tr>
          <td>DHL</td>
          <td>
            <input
              id="length"
              type="text"
              class="form-control"
              v-model="shippingVariables.dhl.fuel"
            />
          </td>
          <td>
            <input
              id="length"
              type="text"
              class="form-control"
              v-model="shippingVariables.dhl.inflation"
            />
          </td>
          <td>
            <input
              id="length"
              type="text"
              class="form-control"
              v-model="shippingVariables.dhl.insurance"
            />
          </td>
          <td>
            <input
              id="length"
              type="text"
              class="form-control"
              v-model="shippingVariables.dhl.bad_service"
            />
          </td>
          <td>
            <input
              id="length"
              type="text"
              class="form-control"
              v-model="shippingVariables.dhl.bad_service_vol"
            />
          </td>
          <td>
            <a
              id="updateValues"
              @click="updateVariables(shippingVariables.dhl, 'DHL')"
              >🔄</a
            >
          </td>
        </tr>
        <tr>
          <td>FedEx</td>
          <td>
            <input
              id="length"
              type="text"
              class="form-control"
              v-model="shippingVariables.fedex.fuel"
            />
          </td>
          <td>
            <input
              id="length"
              type="text"
              class="form-control"
              v-model="shippingVariables.fedex.inflation"
            />
          </td>
          <td>
            <input
              id="length"
              type="text"
              class="form-control"
              v-model="shippingVariables.fedex.insurance"
            />
          </td>
          <td>
            <input
              id="length"
              type="text"
              class="form-control"
              v-model="shippingVariables.fedex.bad_service"
            />
          </td>
          <td>
            <input
              id="length"
              type="text"
              class="form-control"
              v-model="shippingVariables.fedex.bad_service_vol"
            />
          </td>
          <td>
            <a
              id="updateValues"
              @click="updateVariables(shippingVariables.fedex, 'FedEx')"
              >🔄</a
            >
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {},
  data: function () {
    return {
      no_insurance_limit: '',
      checked: '',
      shippingVariables: {
        // inicializei tudo com strings vazias para nao dar erro de undefined ao renderizar (pq começa a renderizar a pagina antes do pedido que define as shippingVariables ter acabado)
        ctt: {
          fuel: '',
          inflation: '',
          insurance: '',
          bad_service: '',
          bad_service_vol: '',
        },
        ctt_expresso: {
          fuel: '',
          inflation: '',
          insurance: '',
          bad_service: '',
          bad_service_vol: '',
        },
        dhl: {
          fuel: '',
          inflation: '',
          insurance: '',
          bad_service: '',
          bad_service_vol: '',
        },
        fedex: {
          fuel: '',
          inflation: '',
          insurance: '',
          bad_service: '',
          bad_service_vol: '',
        },
      },
    };
  },
  methods: {
    async update_ctt_threshold() {
      console.log('update ctt threshold');
      try {
        const payload = {
          company: 'ctt_expresso',
          threshold: this.no_insurance_limit,
        };
        await this.$store.dispatch('updateThreshold', payload);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: 'Limite sem seguro atualizado',
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (error) {
        console.log(error);
      }
    },
    async get_ctt_threshold() {
      console.log('get ctt threshold');
      try {
        const payload = {
          company: 'ctt_expresso',
        };
        await this.$store.dispatch('getThreshold', payload);
        console.log('threshold', this.getCompanyThreshold);
        this.no_insurance_limit = this.getCompanyThreshold;
      } catch (error) {
        console.log(error);
      }
    },
    async getValid() {
      try {
        await this.$store.dispatch('cttValid');
        this.checked = this.getValidCtt;
      } catch (err) {
        alert(err);
      }
    },
    async updateValid() {
      try {
        await this.$store.dispatch('updateCttValid');
        this.getValid();
      } catch (err) {
        alert(err);
      }
    },
    async getShipping() {
      try {
        await this.$store.dispatch('getShippingVariables');
        this.shippingVariables = this.getShippingVariables;
        console.log('1', this.shippingVariables);
      } catch (err) {
        alert(err);
      }
    },
    async updateVariables(company, name) {
      try {
        await this.$store.dispatch('updateShippingVariables', {
          company: company.company,
          fuel: company.fuel,
          inflation: company.inflation,
          insurance: company.insurance,
          back_service: company.bad_service,
          back_service_vol: company.bad_service_vol,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: `Variáveis ${name} atualizado`,
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (err) {
        alert(err);
      }
    },
  },
  async created() {
    await this.getValid();
    await this.getShipping();
    await this.get_ctt_threshold();
  },
  computed: {
    ...mapGetters([
      'getValidCtt',
      'getShippingVariables',
      'getCompanyThreshold',
    ]),
  },
};
</script>

<style>
tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:nth-child(odd) {
  background-color: #fff;
}

tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

tr:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.update-button {
  background-color: #ff8080; /* Tom semelhante ao cabeçalho */
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.update-button:hover {
  background-color: #ff6666; /* Um tom mais escuro para o efeito hover */
}

.update-button:active {
  transform: scale(0.95); /* Pequeno efeito de clique */
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.form-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Adds space between the elements */
  margin-top: 20px; /* Adds some space below the header */
}

label {
  margin-right: 10px; /* Adds space between the label and the input box */
}
</style>
